.input {
  height: 340px;
  background-image: url("../images/alright.jpg");
  background-color: #666666;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  color: #ffffff; }
  .input .cover {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px;
    background: rgba(0, 0, 0, 0.6); }
  .input .category {
    display: flex;
    align-items: center;
    font-size: 2em; }
    .input .category select {
      margin-left: 16px; }
  .input .zip {
    display: flex;
    align-items: center;
    font-size: 2em; }
    .input .zip input[type=text] {
      margin-left: 16px; }
  .input .button {
    padding: 8px 24px;
    background: #CCA800;
    border-radius: 4px;
    user-select: none;
    cursor: pointer;
    margin-top: 24px;
    transition: background 0.2s; }
    .input .button img {
      width: 30px;
      vertical-align: top; }
    .input .button:hover {
      background: #ffd200; }
