.footer {
  color: #666666;
  text-align: center;
  padding: 8px; }
  .footer a,
  .footer a:link,
  .footer a:visited,
  .footer a:active {
    color: #666666; }
  .footer a:hover {
    color: #CCA800; }
