.activityIndicator {
  display: flex;
  align-items: center; }

@keyframes bounce {
  0%, 80%, 100% {
    transform: scale(0.2); }
  40% {
    transform: scale(1); } }
  .activityIndicator .ball {
    width: 16px;
    height: 16px;
    background: #1b1b1b;
    opacity: 0.6;
    border-radius: 100%;
    animation: bounce 1.4s infinite ease-in-out both;
    margin: 2px; }
    .activityIndicator .ball.ball1 {
      animation-delay: -0.32s; }
    .activityIndicator .ball.ball2 {
      animation-delay: -0.16s; }
