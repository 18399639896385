html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Space Mono', monospace; }

select {
  font-family: inherit;
  appearance: none;
  font-size: 0.4em;
  padding: 6px 8px;
  padding-right: 30px;
  border-radius: 4px;
  font-weight: medium;
  border: 1px solid transparent;
  background: #ffffff url("../images/caret.svg") right 8px center/14px 14px no-repeat;
  color: transparent;
  text-shadow: 0 0 0 #000;
  max-width: 100%; }
  select option {
    color: #1b1b1b;
    background: #ffffff; }
  select::-ms-expand {
    display: none; }
  select::-ms-value {
    background: transparent;
    color: transparent; }
  select:focus {
    outline: none; }

input[type='text'],
input[type='number'] {
  padding: 5px 8px;
  border-radius: 4px;
  font-size: 0.4em;
  border: 2px solid transparent;
  transition: border 0.2s;
  font-family: inherit; }
  input[type='text']:focus,
  input[type='number']:focus {
    outline: none;
    border: 2px solid #CCA800; }

h3 {
  color: #CCA800; }

.app .busyIndicator {
  display: flex;
  justify-content: center; }
  .app .busyIndicator .activityIndicator {
    margin: 48px 24px; }

.app .manifesto {
  padding: 48px;
  display: flex;
  align-items: flex-start;
  transition: padding 0.2s; }
  .app .manifesto img {
    width: 300px;
    margin-right: 96px;
    flex-shrink: 0;
    flex-grow: 0;
    transition: margin 0.2s; }
    @media only screen and (max-width: 1000px) {
      .app .manifesto img {
        display: none; } }
  .app .manifesto .statement {
    padding: 8px 0; }
  @media only screen and (max-width: 1200px) {
    .app .manifesto {
      padding: 24px; }
      .app .manifesto img {
        margin-right: 48px; } }

.app .results {
  display: grid;
  grid-auto-rows: min-content;
  grid-gap: 24px;
  grid-template-columns: repeat(auto-fit, minmax(320px, 0.33333fr));
  padding: 24px; }
  .app .results a.result,
  .app .results a.result:link,
  .app .results a.result:visited,
  .app .results a.result:active {
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    text-decoration: none;
    color: #1b1b1b; }
    .app .results a.result .thumbnail,
    .app .results a.result:link .thumbnail,
    .app .results a.result:visited .thumbnail,
    .app .results a.result:active .thumbnail {
      position: relative;
      width: 100%;
      height: 0;
      padding-top: 56.25%;
      background-color: #666666;
      background-size: cover;
      background-position: center;
      display: block; }
      .app .results a.result .thumbnail .gradient,
      .app .results a.result:link .thumbnail .gradient,
      .app .results a.result:visited .thumbnail .gradient,
      .app .results a.result:active .thumbnail .gradient {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent 50%); }
      .app .results a.result .thumbnail .medis,
      .app .results a.result:link .thumbnail .medis,
      .app .results a.result:visited .thumbnail .medis,
      .app .results a.result:active .thumbnail .medis {
        position: absolute;
        bottom: 0;
        right: 0;
        display: flex;
        height: 24px;
        padding: 4px; }
        .app .results a.result .thumbnail .medis img,
        .app .results a.result:link .thumbnail .medis img,
        .app .results a.result:visited .thumbnail .medis img,
        .app .results a.result:active .thumbnail .medis img {
          width: 18px;
          margin: 2px; }
    .app .results a.result .cardContent,
    .app .results a.result:link .cardContent,
    .app .results a.result:visited .cardContent,
    .app .results a.result:active .cardContent {
      padding: 24px; }
      .app .results a.result .cardContent .name,
      .app .results a.result:link .cardContent .name,
      .app .results a.result:visited .cardContent .name,
      .app .results a.result:active .cardContent .name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: bold; }
      .app .results a.result .cardContent .vicinity,
      .app .results a.result:link .cardContent .vicinity,
      .app .results a.result:visited .cardContent .vicinity,
      .app .results a.result:active .cardContent .vicinity {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
      .app .results a.result .cardContent .rating,
      .app .results a.result:link .cardContent .rating,
      .app .results a.result:visited .cardContent .rating,
      .app .results a.result:active .cardContent .rating {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #CCA800; }
  @media only screen and (max-width: 1316px) {
    .app .results {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); } }
